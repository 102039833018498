import React from 'react';
import classNames from 'classnames/bind';

// styles
import styles from './Logo.module.css';

// components
import Text from 'components/Text';

const cx = classNames.bind(styles);

export default function Logo(props) {
    const {
        isLightTheme,
        centered,
        white,
        isOnboarding,
        isFastEasy,
        onboardingLogo,
        logoFooter,
        letterSpacing1,
        logoImgMr8,
        zIndexSmaller,
        logoText,
        logoImg,
    } = props;

    const logoClass = cx('logo', {
        centered,
        white,
        isOnboarding,
        isFastEasy,
        zIndexSmaller: zIndexSmaller,
    });
    const logoImgClass = cx('logoImgDefault', 'logoImgFastEasy', {
        logoImgOnboarding: isOnboarding && onboardingLogo,
        logoFooter: logoFooter,
        logoImgMr8: logoImgMr8,
    });

    const logoTextStyles = cx({
        biggerLogoText: !onboardingLogo,
    });

    return (
        <div className={logoClass}>
            <img src={logoImg} alt="logo" className={logoImgClass} />
            <Text isLightTheme={isLightTheme} caption letterSpacing1={letterSpacing1} bold white={white}>
                <span className={logoTextStyles}>{logoText}</span>
            </Text>
        </div>
    );
}
