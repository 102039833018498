import { Init } from 'testania';

import config from 'config';

// constants
export const DEFAULT_FLOW_PROJECT_NAME = 'fasting';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;

// helpers
import { getVariantBranchName } from './helpers';
import { getMirrorConfigBy } from './mirrorHelpers';

export const getTestaniaInitConfig = (): Init => {
    const { defaultFlow } = getMirrorConfigBy();

    return {
        project: DEFAULT_FLOW_PROJECT_NAME,
        testName: 'default',
        branchName: 'default',
        variantName: getVariantBranchName(),
        // TODO: check if need default
        defaultBranches: ['default'],
        defaultFlow,
        testaniaUrl: config.TESTANIA_HOST,
        testaniaApiKey: config.TESTANIA_X_API_KEY,
        expireConfigTime: DEFAULT_EXPIRE_CONFIG_TIME,
        runOnlyLocalConfig: true,
    };
};
