interface IMeasureUnitSystem {
    [name: string]: string;
}

export const IMPERIAL = 'IMPERIAL';

export const METRIC = 'METRIC';

export const MEASURE_SYSTEM: IMeasureUnitSystem = {
    lbs: IMPERIAL,
    kg: METRIC,
    in: IMPERIAL,
    cm: METRIC,
};

export const WEIGHT_UNIT: IMeasureUnitSystem = {
    IMPERIAL: 'lbs',
    METRIC: 'kg',
};

export const TALL_UNIT: IMeasureUnitSystem = {
    IMPERIAL: 'in',
    METRIC: 'cm',
};
