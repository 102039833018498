import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

// services

import 'services/i18n';
import Sentry from 'services/Sentry/SentryInstance';

import ErrorBoundary from 'modules/ErrorHandler';

import configureStore from './configureStore';
import App from './App';

import './styles.css';

const { store, persistor } = configureStore();
Sentry.init();

const container = document.getElementById('app') as HTMLDivElement;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        {/* @ts-ignore*/}
        <PersistGate persistor={persistor}>
            {/* @ts-ignore*/}
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </PersistGate>
    </Provider>
);
