import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames/bind';
import { Trans } from 'react-i18next';
import { withTheme } from 'wikr-core-components';

// redux
import { selectHideProgressBar } from 'redux/UiEffects/selectors';
import { selectOnboardingScreensList } from 'redux/Onboadring/selectors';

// components
import Logo from 'components/Logo';
import Button from 'components/Button';
import * as S from './styled';

// images
import BackIcon from './img/icon-back.svg';
import SkipArrow from 'assets/img/items/skipArrow.svg';

// styles
import styles from './Header.module.css';

// config
import { LOGO_CONFIG } from './mirrorsConfig';

const cx = classNames.bind(styles);

function Header(props) {
    const {
        title,
        squareBtn,
        boldButton,
        getPlan,
        rowElements,
        history,
        centered,
        getPlanClick,
        disabled,
        isLightTheme,
        onSkipClick,
        letterSpacing1,
        theme,
    } = props;

    const headerClass = cx('header', 'fastEasy', {
        rowElements,
    });

    const onboardingScreensList = useSelector(selectOnboardingScreensList);
    const isHideProgressBar = useSelector(selectHideProgressBar);

    const currentLocationName = location.pathname.replace(/(\/([a-z]{2})\/)|\//g, '');
    const currentPageCount = onboardingScreensList[currentLocationName]?.index;
    const screensNumber = Object.keys(onboardingScreensList).length;

    const calculateProgressWidth = () => (currentPageCount / screensNumber) * 100 + '%';

    const onboardingHeader = onboardingScreensList[currentLocationName] && !isHideProgressBar;

    const mirrorLogo = LOGO_CONFIG[window.location.host];

    const headerContent = () => {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.top}>
                    <If condition={onboardingHeader}>
                        <div onClick={() => history.goBack()}>
                            <BackIcon className={styles.iconBack} />
                        </div>
                    </If>
                    <Logo
                        isLightTheme={isLightTheme}
                        isFastEasy
                        isOnboarding={onboardingScreensList[currentLocationName]}
                        centered={centered}
                        onboardingLogo={onboardingHeader}
                        letterSpacing1={letterSpacing1}
                        logoText={mirrorLogo.logoText}
                        logoImg={mirrorLogo.logoImg}
                    />
                    <If condition={onboardingHeader}>
                        <div className={styles.pageCounter}>
                            <S.PageCounterPrimary theme={theme}>{currentPageCount}</S.PageCounterPrimary>
                            <S.PageSeparator theme={theme}>/</S.PageSeparator>
                            <span className={styles.pageEnd}>{screensNumber}</span>
                        </div>
                    </If>
                </div>
                <If condition={onboardingHeader}>
                    <div className={styles.progressBox}>
                        <S.ProgressBar theme={theme} style={{ width: calculateProgressWidth() }} />
                    </div>
                </If>
                <If condition={getPlan}>
                    <Button
                        dataLocator="headerNavBtn"
                        onClick={getPlanClick}
                        title={title}
                        primary
                        rounded
                        small
                        squareBtn={squareBtn}
                        disabled={disabled}
                        bold={boldButton}
                        isFastEasy
                    />
                </If>
                <If condition={onSkipClick}>
                    <button onClick={onSkipClick} className={styles.skipButton}>
                        <Trans>skip</Trans>
                        <SkipArrow />
                    </button>
                </If>
            </div>
        );
    };

    return <header className={headerClass}>{headerContent()}</header>;
}

Header.defaultProps = {
    title: 'get my plan',
    squareBtn: false,
};

export default withRouter(withTheme(Header));
