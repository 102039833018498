import { SeverityLevel } from '@sentry/react';

const CRITICAL: SeverityLevel = 'fatal';
const ERROR: SeverityLevel = 'error';
const WARNING: SeverityLevel = 'warning';

const ERROR_LEVELS = {
    CRITICAL,
    ERROR,
    WARNING,
};

const SENTRY_FACEBOOK = 'FACEBOOK';
const SENTRY_ONE_TRUST = 'ONE_TRUST';
const SENTRY_PAYMENT = 'PAYMENTS';
const SENTRY_TESTANIA = 'TESTANIA';
const SENTRY_ANALYTIC = 'ANALYTIC';
const SENTRY_AXIOS = 'APP_AXIOS';
const SENTRY_APP = 'APP';

const BLACK_LIST_HANDLED_ERROR_NAMES = [
    'InvalidSignatureException',
    'totalRetryDelay',
    'timeout of 10000ms exceeded',
    'Can\'t find variable: iom',
    'Can\'t find variable: stopYouTubeVideos',
    'Window closed for postrobot_method before ack',
    'Can\'t find variable: clickPauseText2SpeechButton',
    'Right side of assignment cannot be destructured',
    'The object can not be cloned.',
    'undefined is not an object (evaluating \'t.default\')',
    'Data Collector must be created with Kount and/or PayPal.',
    'Can\'t find variable: PaymentAutofillConfig',
    'null is not an object (evaluating \'document.getElementById(\'taboola_ad_script\').getBoundingClientRect\')',
];

export {
    SENTRY_AXIOS,
    SENTRY_APP,
    SENTRY_PAYMENT,
    SENTRY_TESTANIA,
    SENTRY_ANALYTIC,
    ERROR_LEVELS,
    BLACK_LIST_HANDLED_ERROR_NAMES,
    SENTRY_ONE_TRUST,
    SENTRY_FACEBOOK,
};
