/* eslint-disable max-lines */
import { takeLatest, put, select, call } from 'redux-saga/effects';
import { Main } from 'wikr-core-analytics';
import Solid from 'solid-payment';

// api
import api from 'apiSingleton';

// redux
import * as paymentActionTypes from 'redux/Payment/actionTypes';
import { SET_GUIDE_ID } from 'redux/User/actionTypes';
import { selectUserId } from 'redux/User/selectors';
import {
    selectUpsellId,
    selectCurrentProduct,
    selectPaymentMethod,
    selectCheckoutOrderId,
    selectCurrency,
    selectOneClickPaymentPrice,
} from 'redux/Payment/selectors';
import { selectTestaniaName, selectFlowLink } from 'redux/Testania/selectors';
import {
    setInitPaymentData,
    setSubscriptionId,
    setValidatePaymentData,
    initOneClickPayment as initOneClickPaymentAction,
} from 'redux/Payment/actions';

// types
import { showModal } from 'redux/UiEffects/actions';

// interfaces
import { Currency } from 'redux/sagas/user/interfaces';
import { ValidateResponse } from 'types/payments/validate';
import { CurrentProduct, PaymentMethod } from 'interfaces/Payments/payments';
import { ToNextPagePayload } from 'types/payments/payments';
import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { RootState } from 'redux/rootReducer';
import { IOneClickPayment, IOneClickPaymentLib, IValidateRecurringPayment } from 'types/payments/oneClickPayment';

// constants
import { PAYMENT_TYPES_NAME, PAYMENT_TYPES_NAME_ID } from 'constants/payments';

// helpers
import { getPaymentIdByMethod } from 'helpers/utils';
import { deleteSlash, cleanObject, getPriceFromCents, generateQueryParams } from 'helpers/utilsUpdated';

// config
import config from 'config';

const getOneClickPrice = (state: RootState) => selectOneClickPaymentPrice(state);
const getCurrency = (state: RootState) => selectCurrency(state);
const getUpsellId = (state: RootState) => selectUpsellId(state);
const getOrderId = (state: RootState) => selectCheckoutOrderId(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const getUserId = (state: RootState) => selectUserId(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const testaniaName = (state: RootState) => selectTestaniaName(state);
const getFlowLink = (state: RootState) => selectFlowLink(state);

function* init(data: ReturnType<typeof initOneClickPaymentAction>) {
    const { payload } = data;
    const oneClickPrice: number = yield select(getOneClickPrice);
    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const currency: Currency = yield select(getCurrency);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
    const upsellId: string = yield select(getUpsellId);
    const paymentType = currentProduct?.payment_type;

    const paymentSystem = PAYMENT_TYPES_NAME_ID[paymentMethod];

    const isLifetime = paymentType === 'lifetime';
    const isSubscription = paymentType === 'subscription';

    const userId: string = yield select(getUserId);
    const price: number = getPriceFromCents(oneClickPrice);
    const abTestName: string | null = localStorage.getItem('testania_name');
    const urlParams = generateQueryParams();
    const ltvValue = Number(getPriceFromCents(currentProduct?.ltv));

    const analyticData = {
        currency: currency.name,
        value: ltvValue,
        content_id: `Product - Price ${price}`,
        price,
        payment: PAYMENT_TYPES_NAME[paymentMethod],
        card_type: payload?.data?.brand,
        screen_id: payload?.eventName || 'upsale',
        ab_test_name: abTestName,
        user_id: userId,
        order_type: paymentType,
        urlParams,
        tariff: currentProduct?.id,
        mode: config.ENV,
        release_date: config.RELEASE_DATE,
    };

    try {
        yield put({ type: paymentActionTypes.SET_LOADING_STATUS, payload: true });

        const otherAnalyticData: object = yield Main.getDefaultAmazonAnalyticPurchaseData();

        // @ts-ignore
        const meta: IOneClickPayment | IOneClickPaymentLib = {
            ...(isLifetime && {
                price: oneClickPrice,
                product_code: currentProduct?.id,
                analytic_data: {
                    ...otherAnalyticData,
                    ...analyticData,
                },
            }),
            ...(isSubscription && { product_id: currentProduct?.id }),
            ...(upsellId && { product_code: upsellId?.replace('_full', '') }),
        };
        console.info('==============initOneClickPayment', { paymentSystem, meta });
        // @ts-ignore
        const upsellResponse: PaymentData = yield Solid.oneClickPayment(paymentSystem, meta);

        const responseOrder = upsellResponse?.order;
        const payment_id = isSubscription ? responseOrder?.subscription_id : responseOrder?.order_id;

        const validateMeta: IValidateRecurringPayment = {
            payment_id: payment_id as string,
            payload,
            order_id: responseOrder?.order_id,
            ...(isSubscription && {
                subscription_id: responseOrder?.subscription_id,
                subscription_price: currentProduct?.price,
                tariff: currentProduct?.id,
            }),
        };
        if (payment_id) yield put(setSubscriptionId(payment_id));

        yield put(setInitPaymentData(upsellResponse));

        if (isSubscription) {
            yield call(validateRecurringPayment, validateMeta);
        }

        if (isLifetime) {
            yield call(setUpsellId, upsellId);
            yield call(moveToNextPage, true, payload);
        }
    } catch (e) {
        console.error('init oneClickPayment error', e);

        yield call(moveToNextPage, true, payload);
        yield call(errorHandler);
    } finally {
        yield put({ type: paymentActionTypes.SET_LOADING_STATUS, payload: false });
    }
}

function* validateRecurringPayment(validateMeta: IValidateRecurringPayment) {
    const upsellId: string = yield select(getUpsellId);
    const checkoutOrderId: number = yield select(getOrderId);
    const paymentMethod: string = yield select(getPaymentMethod);
    const paymentMethodId: number = getPaymentIdByMethod(paymentMethod);
    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const flow_link: string = yield select(getFlowLink);

    const ltvValue = Number(getPriceFromCents(currentProduct?.ltv));
    const paymentType = currentProduct?.payment_type;
    const isSubscription = paymentType === 'subscription';
    const isLifetime = paymentType === 'lifetime';

    const ab_test_name: string = yield select(testaniaName);

    const { payment_id, payload } = validateMeta;

    const meta = {
        parent_order_id: checkoutOrderId, // order id from checkout page
        payment_id, // current order id
        payment_type: paymentType, // lifetime hardcoded regarding the task
        payment_method: paymentMethodId,
        product_code: upsellId?.replace('_full', ''),
        payment_screen: deleteSlash(payload?.screenId),
        flow_link,
        ...(isSubscription && {
            product_id: currentProduct?.id,
            subscription_period: currentProduct?.period,
            subscription_trial_period: currentProduct?.trial,
        }),
        ...(ab_test_name && { ab_test_name }),
    };

    try {
        const response: ValidateResponse = yield api.payment.validate(meta);

        if (response.error) {
            yield call(showErrorModal, true);
            yield call(setUpsellId, upsellId);
        }
        const { order_id, payload, orderIdForAnalytic, subscription_id, subscription_price, tariff } = validateMeta;

        const data = {
            value: ltvValue,
            order_id,
            cardBrand: payload?.data?.brand,
            customId: payload?.eventName,
            paymentType,
            orderIdForAnalytic,
            subscription_id,
            subscription_price,
            tariff,
            ...(isSubscription && { customId: 'vip-support' }),
        };

        if (!response.error) {
            yield call(sendAnalyticPurchase, data);
            yield put(setValidatePaymentData({ ...response, result: true }));
            yield call(setUpsellId, upsellId);
            yield call(moveToNextPage, true, payload);
        }
    } catch (e) {
        if (isLifetime) {
            yield put({ type: SET_GUIDE_ID, payload: null });
        }
        /* eslint-disable no-console */
        console.log('validate oneClickPayment error', e);

        yield call(moveToNextPage, true, validateMeta.payload);
        yield call(errorHandler);
    }
}

function* sendAnalyticPurchase(payload: any) {
    try {
        const {
            value,
            cardBrand,
            order_id,
            subscription_id,
            subscription_price,
            tariff,
            customId,
            paymentType,
        } = payload;

        const currency: Currency = yield select(getCurrency);
        const cents: number = yield select(getOneClickPrice);
        const price: number = getPriceFromCents(cents);
        const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
        const userId: string = yield select(getUserId);
        const abTestName: string | null = localStorage.getItem('testania_name');
        const { payment_type } = yield select(getCurrentProduct);
        const urlParams = generateQueryParams();

        localStorage.setItem('paymentType', paymentType || 'lifetime');

        const analyticData = {
            currency: currency.name,
            value,
            content_id: `Product - Price ${price}`,
            price,
            payment: PAYMENT_TYPES_NAME[paymentMethod],
            card_type: cardBrand,
            order_id,
            screen_id: customId || 'upsale',
            ab_test_name: abTestName,
            user_id: userId,
            order_type: payment_type,
            subscription_id,
            urlParams,
            subscription_price,
            tariff,
        };

        Main.purchase(cleanObject(analyticData));
    } catch (e) {
        console.error('sendAnalyticPurchase', e);
    }
}

function* moveToNextPage(validateStatus: boolean, payload: ToNextPagePayload) {
    yield call(payload.toNextPage, validateStatus);
}

function* showErrorModal(isOpen: boolean) {
    yield put(showModal(isOpen));
}

function* errorHandler() {
    yield call(setUpsellId, '100');
}

function* setUpsellId(id: string | number) {
    yield put({ type: paymentActionTypes.SET_UPSELL_PRODUCT_ID, payload: id });
}

// @ts-ignore
export const initOneClickPayment = [takeLatest(paymentActionTypes.INIT_ONE_CLICK_PAYMENT, init)];
