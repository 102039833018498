import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Props, State } from './interface';

// services
import sentry from 'services/Sentry/SentryInstance';

// constants
import { ERROR_LEVELS, SENTRY_APP } from 'constants/sentry';

// components
import { ErrorFallback } from './ErrorFallback';

// redux
import { selectTestaniaName, selectCurrantBranchName } from 'redux/Testania/selectors';

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
        error: null,
    };

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public logError(error: Error) {
        const { testaniaName, currentBranchName } = this.props;

        sentry.logError(error, SENTRY_APP, ERROR_LEVELS.CRITICAL, { testaniaName, currentBranchName });
    }

    public componentDidCatch(error: Error) {
        this.setState({ error });

        this.logError(error);
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorFallback error={this.state.error} />;
        }

        return this.props.children;
    }
}
const mapStateToProps = (state: any) => {
    return {
        testaniaName: selectTestaniaName(state),
        currentBranchName: selectCurrantBranchName(state),
    };
};

export default connect(mapStateToProps, null)(ErrorBoundary);
