/* eslint-disable max-lines */
export const PLUSMENFAST_DEFAULT_FLOW = {
    branch_name: 'general',
    flow_name: 'fasting__general__plusmenfast__Control',
    start_page: { id: 'start_simple_a', name: 'start_simple_a', config: {} },
    onboarding_flow: [
        { id: 'ob_social_proof_a', name: 'ob_social_proof_a', config: {} },
        { id: 'ob_target_zones_mix', name: 'ob_target_zones_mix', config: {} },
        { id: 'ob_body_type_target_mix', name: 'ob_body_type_target_mix', config: {} },
        { id: 'ob_body_type_current_mix', name: 'ob_body_type_current_mix', config: {} },
        { id: 'ob_happy_weight', name: 'ob_happy_weight', config: {} },
        { id: 'ob_pref_eat_place', name: 'ob_pref_eat_place', config: {} },
        { id: 'ob_walking', name: 'ob_walking', config: {} },
        { id: 'ob_sleep', name: 'ob_sleep', config: {} },
        { id: 'ob_stairs', name: 'ob_stairs', config: {} },
        { id: 'ob_water', name: 'ob_water', config: {} },
        { id: 'ob_workout_mix', name: 'ob_workout_mix', config: {} },
        { id: 'ob_weekend', name: 'ob_weekend', config: {} },
        { id: 'ob_goals_mix', name: 'ob_goals_mix', config: {} },
        { id: 'ob_height_a', name: 'ob_height_a', config: {} },
        { id: 'ob_weight_current_a', name: 'ob_weight_current_a', config: {} },
        { id: 'ob_weight_target_a', name: 'ob_weight_target_a', config: {} },
        { id: 'ob_age_a', name: 'ob_age_a', config: {} },
        { id: 'feedback_summary', name: 'feedback_summary', config: {} },
        { id: 'creating_d', name: 'creating_d', config: {} },
        { id: 'email', name: 'email', config: {} },
        { id: 'result', name: 'result', config: {} },
    ],
    payment_flow: [
        {
            id: 'payment_e1_foxtrot4',
            name: 'payment_e1_foxtrot4',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: '048aaa5e-1df9-4480-a292-6ee9c4f79d25',
                    price: 6999,
                    name: 'One-year intro (69.99) - One-year (99.99)',
                    trial: 365,
                    period: 365,
                    product_code: [],
                    price_per_state: 19,
                    start_price_per_state: 27,
                    is_preselected: false,
                    start_price: 9999,
                    product_state: 'per_day',
                    ltv: 5058,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '226e51e5-66a9-4c2d-bc7c-afb88c0814c7',
                    price: 1999,
                    name: 'One-month intro (19.99) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    product_code: [],
                    price_per_state: 64,
                    start_price_per_state: 96,
                    is_preselected: true,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 4463,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: 'd1b582ce-3f21-4620-a833-a98da465a93f',
                    price: 2999,
                    name: 'Three-month intro (29.99) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    product_code: [],
                    price_per_state: 33,
                    start_price_per_state: 50,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 3946,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '77a7d626-fe98-4d6c-950e-94908afa4f84',
                    price: 9999,
                    name: 'One-year intro (99.99) - One-year (99.99)',
                    trial: 365,
                    period: 365,
                    product_code: [],
                    price_per_state: 27,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 9999,
                    product_state: 'per_day',
                    ltv: 7225,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '4955828c-1691-4c50-825f-01f89d3c7d8e',
                    price: 2984,
                    name: 'One-month intro (29.84) + One-month (29.84)',
                    trial: 31,
                    period: 31,
                    product_code: [],
                    price_per_state: 96,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 2984,
                    product_state: 'per_day',
                    ltv: 5391,
                    processor: 'solid',
                },
                {
                    payment_type: 'subscription',
                    id: '31caa3cb-26fe-41a8-9921-3461b2c8c91e',
                    price: 4476,
                    name: 'Three-month intro (44.76) + Three-month (44.76)',
                    trial: 90,
                    period: 90,
                    product_code: [],
                    price_per_state: 49,
                    start_price_per_state: 0,
                    is_preselected: false,
                    start_price: 4476,
                    product_state: 'per_day',
                    ltv: 4528,
                    processor: 'solid',
                },
            ],
            config: {},
        },
    ],
    expire: 1682070693742,
};
