import { Main } from 'wikr-core-analytics';
import { hotjar } from 'react-hotjar';
import config from 'config';

// constants
import { HOTJAR_VERSION } from 'constants/versions';
import { MENS_FASTING, FAST_EASY_GIRL, FAST_EASY_WOMEN, FASTING_BOYS } from 'constants/mirrorsHosts';

// const HOTJAR_NUMBER_ID = +config?.HOTJAR_ID;

export const initAmazonAnalytic = () => {
    Main.initSystems({
        activeSystems: [
            {
                name: 'amazon',
                id: config.AMAZON_ID,
                config: {
                    releaseDate: config.RELEASE_DATE,
                    streamName: config.AWS_DELIVERY_STREAM_NAME,
                    envMode: config.ENV,
                },
            },
        ],
    });
};

const HOTJAR_IDS = {
    [MENS_FASTING]: 3289728,
    [FASTING_BOYS]: 3289730,
    [FAST_EASY_WOMEN]: 3289732,
    [FAST_EASY_GIRL]: 3286774,
};

const getHotjarId = () => {
    const host = window.location.host;

    return HOTJAR_IDS[host];
};

export const initHotjar = () => {
    const hotjarId = getHotjarId();

    hotjarId && hotjar.initialize(hotjarId, HOTJAR_VERSION);
};
