import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// redux
import { setPaymentConfig } from 'redux/Payment/actions';

// helpers
import { getRelease } from 'helpers/utilsUpdated';

const RELEASE_CONFIG = {
    paypal: true,
    applaPay: true,
    sandbox: false,
};

function ReleaseConfig() {
    const isRelease = getRelease();
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isRelease) {
            setConfig(RELEASE_CONFIG);
        } else {
            getFlags();
        }
    }, []);

    const parse = (param) => {
        try {
            return JSON.parse(param);
        } catch (error) {
            console.error(error);

            return param;
        }
    };

    const getFlags = () => {
        const url = new URLSearchParams(location.search);
        const config = {
            paypal: parse(url.get('paypal')),
            applaPay: parse(url.get('applepay')),
            sandbox: parse(url.get('sandbox')),
        };
        setConfig(deleteIsNullUndefined(config));
    };

    const setConfig = (config) => {
        dispatch(setPaymentConfig(config));
    };

    const deleteIsNullUndefined = (config) => {
        for (let propName in config) {
            if (config[propName] === null || config[propName] === undefined) {
                delete config[propName];
            }
        }
        if (Object.keys(config).length !== 0) {
            return config;
        } else {
            return RELEASE_CONFIG;
        }
    };

    return <div />;
}

export default ReleaseConfig;
