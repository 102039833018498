import styled from 'styled-components';

export const ProgressBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 5px;
    background-color: ${({ theme }) => theme['primary-default']};
    transition: width 0.2s linear;
`;

export const PageCounterPrimary = styled.span`
    font-weight: 500;
    color: ${({ theme }) => theme['primary-default']};
`;

export const PageSeparator = styled.span`
    color: ${({ theme }) => theme['primary-default']};
    margin: 0 3px;
`;
