import React, { ReactNode } from 'react';
import { ThemeProvider as WikrThemeProvider } from 'wikr-core-components';

// hooks
import useThemeConfiguration from 'hooks/useThemeConfiguration';

// theme
const themeConfig = require('themeConfig.json');

interface ThemeProviderProps {
    children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
    const theme = useThemeConfiguration(themeConfig);

    return <WikrThemeProvider theme={theme}>{children}</WikrThemeProvider>;
};
