import * as actionTypes from './actionTypes';

// interface
import { UserStore } from 'types/store/userStore';
import { InferValueTypes } from 'types/commonInterfaces';

// constants
import { DEFAULT_COUNTRY } from 'constants/countriesList';

// actions
import * as action from './actions';

type ActionType = ReturnType<InferValueTypes<typeof action>>;

const initialState: UserStore = {
    email: null,
    orderId: null,
    user_id: null,
    emailStatus: {
        isValid: false,
        message: '',
    },
    isLoading: false,
    token: null,
    payment_type: null,
    guideId: null,
    loader: false,
    country: localStorage.getItem('country') || DEFAULT_COUNTRY,
    lastUpdateAt: null,
    isPaid: false,
    platform: null,
    isAutologinComplete: false,
    deepLink: JSON.parse(localStorage.getItem('deeplinkData') || 'false') || null,
    isSendPulseActive: false,
    isSendPulseActiveDisplay: true,
};

const UserReducer = (state = initialState, action: ActionType) => {
    switch (action.type) {
        case actionTypes.SIGN_UP:
        case actionTypes.UPDATE_INFO:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.SET_LOADER:
            return { ...state, loader: action.payload };

        case actionTypes.SIGN_UP_SUCCESS:
        case actionTypes.UPDATE_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };

        case actionTypes.SIGN_UP_FAIL:
        case actionTypes.UPDATE_INFO_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };

        case actionTypes.SET_EMAIL:
            return { ...state, email: action.payload };
        case actionTypes.SET_ORDER_ID:
            return { ...state, orderId: action.payload };
        case actionTypes.SET_USER_ID:
            return { ...state, user_id: action.payload };
        case actionTypes.SET_GUIDE_ID:
            return { ...state, guideId: action.payload };
        case actionTypes.SET_USER_TOKEN:
            localStorage.setItem('token', action.payload);

            return { ...state, token: action.payload };
        case actionTypes.SET_COUNTRY:
            localStorage.setItem('country', action.payload);

            return { ...state, country: action.payload };
        case actionTypes.UPDATE_SENDPULS_MAILING:
            return { ...state, isLoading: true };
        case actionTypes.UPDATE_SENDPULS_MAILING_SUCCESS:
            return { ...state, isLoading: false };
        case actionTypes.UPDATE_SENDPULS_MAILING_FAIL:
            return { ...state, isLoading: false };
        case actionTypes.SET_IS_PAID:
            return { ...state, isPaid: action.payload };
        case actionTypes.SET_UPDATED_AT_TIME:
            return { ...state, lastUpdateAt: action.payload };
        case actionTypes.SET_IS_AUTOLOGIN_COMPLETE:
            return { ...state, isAutologinComplete: action.payload };
        case actionTypes.SET_PLATFORM:
            return { ...state, platform: action.payload };
        case actionTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };
        case actionTypes.SET_DEEP_LINK:
            return { ...state, deepLink: action.payload, isLoading: false };
        case actionTypes.SENDPULSE_MAILING_ACTIVE:
            return { ...state, isSendPulseActive: action.payload };
        case actionTypes.SENDPULSE_MAILING_ACTIVE_DISPLAY:
            return { ...state, isSendPulseActiveDisplay: action.payload };
        default:
            return state;
    }
};

export default UserReducer;
