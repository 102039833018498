import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from './ModalClose.module.css';

ModalClose.defaultProps = {
    onClick: () => {},
};

ModalClose.propTypes = {
    onClick: PropTypes.func,
};

function ModalClose(props) {
    const { notificationModalIcon, isLeftClose, onClick, isFixed, isSmallClose } = props;

    const cx = classNames.bind(styles);
    const modalStyles = cx('closePopup', {
        closePopupFixed: isFixed,
        notificationModal__close: notificationModalIcon,
        closePopup__left: isLeftClose,
        closePopup__small: isSmallClose,
    });

    return <span className={modalStyles} onClick={onClick} />;
}

export default ModalClose;
