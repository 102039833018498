/* ToDo: roundExp experiment 24.09 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

// helpers
import classNames from 'classnames/bind';

// components
import Loader from 'components/Loader';
import LazyImage from 'components/LazyImage';

// styles
import base from 'assets/styles/Common.module.css';
import styles from './Button.module.css';

// images
import Arrow from './img/arrow.svg';
import payPalIco from 'assets/img/paymentMethods/payPal.webp';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Button(props) {
    const {
        globalClassNames,
        title,
        onClick,
        inverse,
        animation,
        animationFast,
        primary,
        rounded,
        small,
        paypalButton,
        disabled,
        isLoading,
        type,
        bold,
        arrowed,
        dataLocator,
        isOnePrice,
        squareBtn,
        notificationBtn,
        pulse,
        btnConsent,
        isFastEasy,
        femaleFast,
        maleFast,
        noMargin,
        exclusive,
        roundExp,
        skip,
    } = props;

    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));
    const btnClass = cx(
        'default',
        {
            primary,
            animation,
            animationFast,
            inverse,
            rounded,
            small,
            paypalButton,
            bold,
            arrowedLeft: arrowed === 'left',
            arrowedRight: arrowed === 'right',
            isOnePrice: isOnePrice,
            squareBtn: squareBtn,
            notificationBtn: notificationBtn,
            pulse,
            btnConsent: btnConsent,
            isFastEasy: isFastEasy,
            femaleFast,
            maleFast,
            noMargin,
            exclusive,
            roundExp,
            skip,
        },
        pulse,
        classes
    );

    useEffect(() => {
        if (type === 'submit') {
            const handleSubmit = (e) => {
                if (e.code === 'Enter') handleClick();
            };

            window.addEventListener('keypress', handleSubmit);

            return () => {
                window.removeEventListener('keypress', handleSubmit);
            };
        }
    }, []);

    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <button
            data-locator={dataLocator}
            type={type}
            disabled={disabled || isLoading}
            className={btnClass}
            onClick={handleClick}
        >
            <>
                <Trans>{title}</Trans>
                <Loader isLoading={isLoading} additionalClass={[paypalButton ? 'payPal' : '']} />
                <If condition={arrowed}>
                    <Arrow className={styles.arrow} />
                </If>
                <If condition={paypalButton}>
                    <div className={styles.imageWrapper}>
                        <LazyImage src={payPalIco} />
                    </div>
                </If>
            </>
        </button>
    );
}

Button.propTypes = {
    globalClassNames: PropTypes.array,
    title: PropTypes.string,
    onClick: PropTypes.func,
    inverse: PropTypes.bool,
    animation: PropTypes.bool,
    animationFast: PropTypes.bool,
    primary: PropTypes.bool,
    rounded: PropTypes.bool,
    small: PropTypes.bool,
    paypalButton: PropTypes.bool,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    type: PropTypes.string,
};

Button.defaultProps = {
    className: '',
    globalClassNames: [],
    onClick: () => {},
    disabled: false,
    isLoading: false,
    type: 'button',
};

export default Button;
