import { useState } from 'react';

// helpers
import { getFaviconElement } from 'helpers/utilsUpdated';

import { Theme } from 'wikr-core-components';

interface ThemeConfigScheme {
    inlineStyle: Record<string, string>;
    themeStyle: Theme;
    faviconUrl: string;
    logoUrl: string;
}

const useThemeConfiguration = (themeConfig: Record<string, ThemeConfigScheme>) => {
    const [theme, setTheme] = useState<Theme>();

    if (!theme) {
        const hostName = window.location.host;

        const { themeStyle, faviconUrl } = themeConfig?.[hostName];

        const faviconElement = getFaviconElement() as HTMLLinkElement;

        faviconElement.href = faviconUrl;

        setTheme(themeStyle);
    }

    return theme ?? themeConfig['default']?.themeStyle;
};

export default useThemeConfiguration;
