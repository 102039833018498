import { getValidatedStatus } from './helpers';
import { getCheckedFlows } from './flowCheck';
import { getIntroductoryFlowValidator } from './validators/introductoryFlowValidator';
import { getScreenIdsValidator } from './validators/screenIdsValidator';

export const validateFlow = (config) => {
    let status = true;
    let currentFlowName = null;

    const runFlow = (flow, flowName, flowValidator) => {
        currentFlowName = flowName;

        const { products } = flow;

        const validator = flowValidator(products);

        status = getValidatedStatus(validator);
    };

    const runConfig = () => {
        const validator = getScreenIdsValidator(config);

        status = getValidatedStatus(validator);
    };

    runConfig();

    const { isIntroductoryAPage, isIntroductoryBPage } = getCheckedFlows(config);

    if (isIntroductoryAPage && status) {
        runFlow(isIntroductoryAPage, 'IntroductoryA', getIntroductoryFlowValidator);
    }

    if (isIntroductoryBPage && status) {
        runFlow(isIntroductoryBPage, 'IntroductoryB', getIntroductoryFlowValidator);
    }

    return Promise.resolve({ name: `validate ${currentFlowName} flow`, status });
};
