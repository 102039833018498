// constants
import { FASTEASYGIRL_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/fasteasygirlFlow';
import { MENSFASTING_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/mensfastingFlow';
import { FASTEASYWOMEN_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/fasteasywomenFlow';
import { FASTINGBOYS_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/fastingboysFlow';
import { MUSCULARFAST_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/muscularfastFlow';
import { PLUSFASTING_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/plusfastingFlow';
import { PLUSWOMENFAST_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/pluswomenfastFlow';
import { WOMENSTARTFASTING_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/womenstartfastingFlow';
import { PLUSMENFAST_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/plusmenfastFlow';
import { FASTSLIMGIRL_DEFAULT_FLOW } from 'constants/mirrorsDefaultFlows/fastslimgirlFlow';

// types
import { FlowStructure } from 'testania';

// constants
import {
    FAST_EASY_GIRL,
    FAST_EASY_WOMEN,
    FASTING_BOYS,
    MENS_FASTING,
    MUSCULAR_FAST,
    PLUS_FASTING,
    PLUS_WOMEN_FAST,
    WOMEN_START_FASTING,
    PLUS_MEN_FAST,
    FAST_SLIM_GIRL,
} from 'constants/mirrorsHosts';

interface I_FLOW_CONFIG_BY_DOMAIN {
    [domain: string]: { defaultFlow: FlowStructure };
}

const FLOW_CONFIG_BY_DOMAIN: I_FLOW_CONFIG_BY_DOMAIN = {
    [FAST_EASY_GIRL]: {
        defaultFlow: FASTEASYGIRL_DEFAULT_FLOW,
    },
    [MENS_FASTING]: {
        defaultFlow: MENSFASTING_DEFAULT_FLOW,
    },
    [FASTING_BOYS]: {
        defaultFlow: FASTINGBOYS_DEFAULT_FLOW,
    },
    [FAST_EASY_WOMEN]: {
        defaultFlow: FASTEASYWOMEN_DEFAULT_FLOW,
    },
    [MUSCULAR_FAST]: {
        defaultFlow: MUSCULARFAST_DEFAULT_FLOW,
    },
    [PLUS_FASTING]: {
        defaultFlow: PLUSFASTING_DEFAULT_FLOW,
    },
    [PLUS_WOMEN_FAST]: {
        defaultFlow: PLUSWOMENFAST_DEFAULT_FLOW,
    },
    [WOMEN_START_FASTING]: {
        defaultFlow: WOMENSTARTFASTING_DEFAULT_FLOW,
    },
    [PLUS_MEN_FAST]: {
        defaultFlow: PLUSMENFAST_DEFAULT_FLOW,
    },
    [FAST_SLIM_GIRL]: {
        defaultFlow: FASTSLIMGIRL_DEFAULT_FLOW,
    },
};

export const getMirrorConfigBy = () => {
    const host = window.location.host;

    return FLOW_CONFIG_BY_DOMAIN[host];
};
