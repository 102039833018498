import React, { useEffect } from 'react';
import LazyLoad, { forceVisible } from 'react-lazyload';

import Placeholder from './Placeholder';
import styles from './LazyImage.module.css';
import PropTypes from 'prop-types';

function LazyImage({ height, src, alt, offset, once, isForceVisible, imgHeight }) {
    const loaderHeight = { minHeight: height };
    const imageHeight = { height: imgHeight };

    useEffect(() => {
        if (isForceVisible) forceVisible();
    }, [isForceVisible]);

    return (
        <div style={loaderHeight}>
            <LazyLoad
                height={height}
                style={loaderHeight}
                offset={offset}
                once={once}
                placeholder={<Placeholder />}
                debounce={10}
            >
                <img src={src} alt={alt} className={styles.image} style={imageHeight} />
            </LazyLoad>
        </div>
    );
}

LazyImage.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    offset: PropTypes.number,
    once: PropTypes.bool,
    height: PropTypes.number,
    isForceVisible: PropTypes.bool,
};

LazyImage.defaultProps = {
    once: false,
    isForceVisible: false,
    height: 20,
};

export default LazyImage;
