import React from 'react';
import PropTypes from 'prop-types';

// styles
import * as S from './styled';

const Loader = ({ isLoading, additionalClass }) => {
    return (
        <If condition={isLoading}>
            <S.Loader additionalClass={additionalClass} />
        </If>
    );
};

Loader.propTypes = {
    isLoading: PropTypes.bool,
    additionalClass: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export default Loader;
