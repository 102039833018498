/* eslint-disable max-lines */
export default [
    {
        path: 'OnboardingPages/ObTagsA',
        route: 'ob-tags-a',
    },
    {
        path: 'OnboardingPages/ObTagsB',
        route: 'ob-tags-b',
    },
    {
        path: 'OnboardingPages/ObWhatIsFastingA',
        route: 'ob-what-is-fasting-a',
    },
    {
        path: 'OnboardingPages/ObWhatIsFastingB',
        route: 'ob-what-is-fasting-b',
    },
    {
        path: 'OnboardingPages/ObBehaveChoiceSc',
        route: 'ob-behave-choice-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveEatingSc',
        route: 'ob-behave-eating-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveMultitaskingSc',
        route: 'ob-behave-multitasking-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveRoutinesSc',
        route: 'ob-behave-routines-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveOldHabitsSc',
        route: 'ob-behave-oldhabits-sc',
    },
    {
        path: 'OnboardingPages/ObBehaveImpulseSc',
        route: 'ob-behave-impulse-sc',
    },
    {
        path: 'OnboardingPages/ObGoalSet',
        route: 'ob-goal-set',
    },
    {
        path: 'OnboardingPages/ObGoalSetA',
        route: 'ob-goal-set-a',
    },
    {
        path: 'OnboardingPages/ObGoalsOver',
        route: 'ob-goals',
    },
    {
        path: 'OnboardingPages/ObGoalsOld',
        route: 'ob-goals-old',
    },
    {
        path: 'OnboardingPages/ObGoalsOldA',
        route: 'ob-goals-old-a',
    },
    {
        path: 'OnboardingPages/ObGoalsMix',
        route: 'ob-goals-mix',
    },
    {
        path: 'OnboardingPages/ObGoalsMixA',
        route: 'ob-goals-mix-a',
    },
    {
        path: 'OnboardingPages/ObGoalsMixB',
        route: 'ob-goals-mix-b',
    },
    {
        path: 'OnboardingPages/Creating',
        route: 'creating',
    },
    {
        path: 'OnboardingPages/CreatingD',
        route: 'creating-d',
    },
    {
        path: 'StartPages/StartAge',
        route: 'start-age',
    },
    {
        path: 'StartPages/StartAgeD',
        route: 'start-age-d',
    },
    {
        path: 'StartPages/StartAgeOldB',
        route: 'start-age-old-b',
    },
    {
        path: 'StartPages/StartAgeOldValue',
        route: 'start-age-old-value',
    },
    {
        path: 'StartPages/StartAgeOldValue2',
        route: 'start-age-old-value-2',
    },
    {
        path: 'StartPages/StartAgeMix',
        route: 'start-age-mix',
    },
    {
        path: 'StartPages/StartAgeMixA',
        route: 'start-age-mix-a',
    },
    {
        path: 'StartPages/StartUnisex',
        route: 'start-unisex',
    },
    {
        path: 'StartPages/StartAgeMixA',
        route: 'index',
    },
    {
        path: 'StartPages/StartArticle',
        route: 'start-article',
    },
    {
        path: 'StartPages/StartArticleA',
        route: 'start-article-a',
    },
    {
        path: 'StartPages/StartSimpleA',
        route: 'start-simple-a',
    },
    {
        path: 'StartPages/StartSimpleB',
        route: 'start-simple-b',
    },
    {
        path: 'StartPages/StartSimpleC',
        route: 'start-simple-c',
    },
    {
        path: 'StartPages/StartBlogger',
        route: 'start-blogger',
    },
    {
        path: 'StartPages/StartBloggerA',
        route: 'start-blogger-a',
    },
    {
        path: 'StartPages/StartSeniorA',
        route: 'start-senior-a',
    },
    {
        path: 'StartPages/StartSeniorB',
        route: 'start-senior-b',
    },
    {
        path: 'StartPages/StartSeniorC',
        route: 'start-senior-c',
    },
    {
        path: 'StartPages/StartPageChart',
        route: 'start-page-chart',
    },
    {
        path: 'StartPages/StartPageChartLong',
        route: 'start-page-chart-long',
    },
    {
        path: 'OnboardingPages/EmailConsent',
        route: 'email-consent',
    },
    {
        path: 'PaymentLandingPages/Upsell',
        route: 'upsell',
    },
    {
        path: 'PaymentLandingPages/UpsellTwo',
        route: 'upsell-2',
    },
    {
        path: 'GeneralPages/VipSupport',
        route: 'vip-support',
    },
    {
        path: 'OnboardingPages/ObHeightA',
        route: 'ob-height-a',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightA',
        route: 'ob-weight-current-a',
    },
    {
        path: 'OnboardingPages/ObCurrentWeightC',
        route: 'ob-weight-current-c',
    },
    {
        path: 'OnboardingPages/ObTargetWeightA',
        route: 'ob-weight-target-a',
    },
    {
        path: 'OnboardingPages/ObLooseWeightReason',
        route: 'ob-loose-weight-reason',
    },
    {
        path: 'OnboardingPages/ObLooseWeightReasonA',
        route: 'ob-loose-weight-reason-a',
    },
    {
        path: 'OnboardingPages/ObAgeA',
        route: 'ob-age-a',
    },
    {
        path: 'OnboardingPages/ObFastingAwareness',
        route: 'ob-fasting-awareness',
    },
    {
        path: 'OnboardingPages/CurrentBody',
        route: 'ob-body-type-current',
    },
    {
        path: 'OnboardingPages/CurrentBodyTypeMix',
        route: 'ob-body-type-current-mix',
    },
    {
        path: 'OnboardingPages/TargetBody',
        route: 'ob-body-type-target',
    },
    {
        path: 'OnboardingPages/TargetBodyTypeMix',
        route: 'ob-body-type-target-mix',
    },
    {
        path: 'OnboardingPages/TargetZones',
        route: 'ob-target-zones',
    },
    {
        path: 'OnboardingPages/TargetZonesMix',
        route: 'ob-target-zones-mix',
    },
    {
        path: 'OnboardingPages/BreakfastTime',
        route: 'ob-time-breakfast',
    },
    {
        path: 'OnboardingPages/ObBadHabits',
        route: 'ob-bad-habits',
    },
    {
        path: 'OnboardingPages/LunchTime',
        route: 'ob-time-lunch',
    },
    {
        path: 'OnboardingPages/DinnerTime',
        route: 'ob-time-dinner',
    },
    {
        path: 'OnboardingPages/PreferredPlace',
        route: 'ob-pref-eat-place',
    },
    {
        path: 'OnboardingPages/FastingWeekend',
        route: 'ob-weekend',
    },
    {
        path: 'OnboardingPages/WorkoutSchedule',
        route: 'ob-workout',
    },
    {
        path: 'OnboardingPages/ObWorkoutA',
        route: 'ob-workout-a',
    },
    {
        path: 'OnboardingPages/ObWorkoutMix',
        route: 'ob-workout-mix',
    },
    {
        path: 'OnboardingPages/JobActive',
        route: 'ob-job-active',
    },
    {
        path: 'OnboardingPages/ObExercises',
        route: 'ob-exercises',
    },
    {
        path: 'OnboardingPages/ObExercisesFeedback',
        route: 'ob-exercises-feedback',
    },
    {
        path: 'OnboardingPages/FastingSleep',
        route: 'ob-sleep',
    },
    {
        path: 'OnboardingPages/FastingWater',
        route: 'ob-water',
    },
    {
        path: 'OnboardingPages/FastingMotivation',
        route: 'ob-motivation',
    },
    {
        path: 'OnboardingPages/HappyWeight',
        route: 'ob-happy-weight',
    },
    {
        path: 'OnboardingPages/ObFastingIntervalA',
        route: 'ob-fasting-interval-a',
    },
    {
        path: 'OnboardingPages/ObFoodInFridge',
        route: 'ob-food-in-fridge',
    },
    {
        path: 'OnboardingPages/ObMetabolism',
        route: 'ob-metabolism',
    },
    {
        path: 'OnboardingPages/ObSemiFinishedFood',
        route: 'ob-semi-finished-food',
    },
    {
        path: 'OnboardingPages/WorkloadType',
        route: 'ob-job-load',
    },
    {
        path: 'ResultPages/Result',
        route: 'result',
    },
    {
        path: 'ResultPages/ResultA',
        route: 'result-a',
    },
    {
        path: 'ResultPages/ResultB',
        route: 'result-b',
    },
    {
        path: 'ResultPages/ResultC',
        route: 'result-c',
    },
    {
        path: 'ResultPages/ResultD',
        route: 'result-d',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffect',
        route: 'ob-weekly-effect',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffectA',
        route: 'ob-weekly-effect-a',
    },
    {
        path: 'OnboardingPages/ObWeeklyEffectB',
        route: 'ob-weekly-effect-b',
    },
    {
        path: 'OnboardingPages/ObStairs',
        route: 'ob-stairs',
    },
    {
        path: 'OnboardingPages/ObWalking',
        route: 'ob-walking',
    },
    {
        path: 'OnboardingPages/ObWalkingA',
        route: 'ob-walking-a',
    },
    {
        path: 'OnboardingPages/ObWaterA',
        route: 'ob-water-a',
    },
    {
        path: 'OnboardingPages/ObSleepA',
        route: 'ob-sleep-a',
    },
    {
        path: 'OnboardingPages/ObBackIssue',
        route: 'ob-back-issue',
    },
    {
        path: 'OnboardingPages/ObMythsA',
        route: 'ob-myths-a',
    },
    {
        path: 'OnboardingPages/ObMythsB',
        route: 'ob-myths-b',
    },
    {
        path: 'OnboardingPages/ObMythsC',
        route: 'ob-myths-c',
    },
    {
        path: 'OnboardingPages/ObStatementFoodEmotions',
        route: 'ob-statement-food-emotions',
    },
    {
        path: 'OnboardingPages/ObStatementClearPlate',
        route: 'ob-statement-clear-plate',
    },
    {
        path: 'OnboardingPages/ObPregnant',
        route: 'ob-pregnant',
    },
    {
        path: 'OnboardingPages/ObMedications',
        route: 'ob-medications',
    },
    {
        path: 'OnboardingPages/ObUnfortunately',
        route: 'ob-unfortunately',
    },
    {
        path: 'OnboardingPages/ObFortunately',
        route: 'ob-fortunately',
    },
    {
        path: 'OnboardingPages/ObTempo',
        route: 'ob-tempo',
    },
    {
        path: 'OnboardingPages/ObOccasion',
        route: 'ob-occasion',
    },
    {
        path: 'OnboardingPages/ObOccasionResult',
        route: 'ob-occasion-result',
    },
    {
        path: 'OnboardingPages/ObOccasionResultDynamic',
        route: 'ob-occasion-result-dynamic',
    },
    {
        path: 'OnboardingPages/ResultWeightOnlyA',
        route: 'result-weight-only-a',
    },
    {
        path: 'OnboardingPages/ResultWeightOnlyB',
        route: 'result-weight-only-b',
    },
    {
        path: 'OnboardingPages/ObTargetWeightB',
        route: 'ob-weight-target-b',
    },
    {
        path: 'OnboardingPages/ObTargetWeightC',
        route: 'ob-weight-target-c',
    },
    {
        path: 'OnboardingPages/ObTargetWeightConfirmation',
        route: 'ob-target-weight-confirmation',
    },
    {
        path: 'OnboardingPages/ObTargetWeightE',
        route: 'ob-weight-target-e',
    },
    {
        path: 'PaymentLandingPages/PaymentAb/PaymentAb9',
        route: 'payment-Ab9-checkout-Alpha3',
    },
    {
        path: 'PaymentLandingPages/PaymentBa/PaymentBa2',
        route: 'payment-Ba2-checkout-Alpha3',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE1Foxtrot4',
        route: 'payment-e1-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE1Foxtrot5',
        route: 'payment-e1-foxtrot5',
    },
    {
        path: 'PaymentLandingPages/PaymentE/PaymentE3Foxtrot4',
        route: 'payment-e3-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG1Foxtrot1',
        route: 'payment-g1-foxtrot1',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG1Foxtrot3',
        route: 'payment-g1-foxtrot3',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG1Foxtrot10',
        route: 'payment-g1-foxtrot10',
    },
    {
        path: 'PaymentLandingPages/PaymentG/PaymentG1Foxtrot12',
        route: 'payment-g1-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA1Foxtrot1',
        route: 'offer-a1-foxtrot1',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA1Foxtrot3',
        route: 'offer-a1-foxtrot3',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA1Foxtrot10',
        route: 'offer-a1-foxtrot10',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferA1Foxtrot12',
        route: 'offer-a1-foxtrot12',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE1Foxtrot4',
        route: 'offer-e1-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE1Foxtrot5',
        route: 'offer-e1-foxtrot5',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE3Foxtrot4',
        route: 'offer-e3-foxtrot4',
    },
    {
        path: 'PaymentLandingPages/PaymentOffer/PaymentOfferE4Foxtrot4',
        route: 'offer-e4-foxtrot4',
    },
    {
        path: 'OnboardingPages/Email',
        route: 'email',
    },
    {
        path: 'OnboardingPages/EmailA',
        route: 'email-a',
    },
    {
        path: 'OnboardingPages/EmailB',
        route: 'email-b',
    },
    {
        path: 'OnboardingPages/EmailC',
        route: 'email-c',
    },
    {
        path: 'OnboardingPages/EmailConfig',
        route: 'email-config',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofA',
        route: 'ob-social-proof-a',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofC',
        route: 'ob-social-proof-c',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofD',
        route: 'ob-social-proof-d',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofB',
        route: 'ob-social-proof-b',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofMix',
        route: 'ob-social-proof-mix',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofAges',
        route: 'ob-social-proof-ages',
    },
    {
        path: 'OnboardingPages/ObSocialProof/ObSocialProofAgesA',
        route: 'ob-social-proof-ages-a',
    },
    {
        path: 'OnboardingPages/FeedbackSummary',
        route: 'feedback-summary',
    },
    {
        path: 'OnboardingPages/ObFoodYellowList',
        route: 'ob-food-yellow-list',
    },
    {
        path: 'OnboardingPages/ObFoodGreenList',
        route: 'ob-food-green-list',
    },
    {
        path: 'OnboardingPages/ObFoodRedList',
        route: 'ob-food-red-list',
    },
    {
        path: 'OnboardingPages/ObFoodIntro',
        route: 'ob-food-intro',
    },
    {
        path: 'OnboardingPages/ObFoodRedInfo',
        route: 'ob-food-red-info',
    },
    {
        path: 'OnboardingPages/ObFoodGreenInfo',
        route: 'ob-food-green-info',
    },
    {
        path: 'OnboardingPages/ObFoodYellowInfo',
        route: 'ob-food-yellow-info',
    },
    {
        path: 'OnboardingPages/FoodResult',
        route: 'food-result',
    },
    {
        path: 'OnboardingPages/ObRestrictiveDiet',
        route: 'ob-restrictive-diet',
    },
    {
        path: 'OnboardingPages/ObCheatMeal',
        route: 'ob-cheat-meal',
    },
    {
        path: 'OnboardingPages/ObSkinTone',
        route: 'ob-skin-tone',
    },
    {
        path: 'OnboardingPages/Ob168Fasting',
        route: 'ob-16-8-fasting',
    },
    {
        path: 'OnboardingPages/ObBenefits',
        route: 'ob-benefits',
    },
    {
        path: 'OnboardingPages/ObBodyTypeCurrentMorph',
        route: 'ob-body-type-current-morph',
    },
    {
        path: 'OnboardingPages/ObFeeling',
        route: 'ob-feeling',
    },
    {
        path: 'OnboardingPages/ObFeelingA',
        route: 'ob-feeling-a',
    },
    {
        path: 'OnboardingPages/ObFeelings',
        route: 'ob-feelings',
    },
    {
        path: 'OnboardingPages/ObFeelingsA',
        route: 'ob-feelings-a',
    },
    {
        path: 'OnboardingPages/ObFeelingsAFeedback',
        route: 'ob-feelings-a-feedback',
    },
    {
        path: 'OnboardingPages/ObTrackersFeedback',
        route: 'ob-trackers-feedback',
    },
    {
        path: 'OnboardingPages/ObFitnessTracker',
        route: 'ob-fitness-tracker',
    },
    {
        path: 'OnboardingPages/ObScale',
        route: 'ob-scale',
    },
    {
        path: 'OnboardingPages/SuccessStatistics',
        route: 'success-statistics',
    },
    {
        path: 'OnboardingPages/GoalDatePrognose1',
        route: 'goal-date-prognose-1',
    },
    {
        path: 'OnboardingPages/GoalDatePrognose2',
        route: 'goal-date-prognose-2',
    },
    {
        path: 'OnboardingPages/ObDiets',
        route: 'ob-diets',
    },
    {
        path: 'GeneralPages/Registration',
        route: 'registration-page',
    },
    {
        path: 'GeneralPages/ThankYou',
        route: 'thank-you',
    },
] as { path: string; route: string }[];
