const HOST = window.location.host;

export const LOCALHOST = 'localhost:8080';
export const STAGE_HOST = 'fasting-front-mirrors-stage.asqq.io';

const DEV_HOSTS = [LOCALHOST, STAGE_HOST];

export const FAST_EASY_GIRL = DEV_HOSTS.includes(HOST) ? HOST : 'fasteasygirl.com';
export const FAST_EASY_WOMEN = 'fasteasywomen.com';
export const PLUS_WOMEN_FAST = 'pluswomenfast.com';
export const WOMEN_START_FASTING = 'womenstartfasting.com';
export const FAST_SLIM_GIRL = 'fastslimgirl.com';

export const MENS_FASTING = 'mensfasting.com';
export const FASTING_BOYS = 'fastingboys.com';
export const MUSCULAR_FAST = 'muscularfast.com';
export const PLUS_FASTING = 'plusfasting.com';
export const PLUS_MEN_FAST = 'plusmenfast.com';

export const HOST_NAMES_FEMALE = [
    LOCALHOST,
    STAGE_HOST,
    FAST_EASY_GIRL,
    FAST_EASY_WOMEN,
    PLUS_WOMEN_FAST,
    WOMEN_START_FASTING,
    FAST_SLIM_GIRL,
];

export const HOST_NAMES_MALE = [MENS_FASTING, FASTING_BOYS, MUSCULAR_FAST, PLUS_FASTING, PLUS_MEN_FAST];
