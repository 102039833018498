import React from 'react';
import classNames from 'classnames/bind';

// styles
import styles from './Title.module.css';
import base from 'assets/styles/Common.module.css';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Title(props) {
    const { globalClassNames, children, h1, h2, h3, h4, h4New, h6, h1New, centered, white, colorFast } = props;

    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const titleClass = cx(
        'default',
        {
            h1,
            h2,
            h3,
            h4,
            h4New,
            h6,
            h1New,
            centered,
            white,
            colorFast,
        },
        classes
    );

    return <h1 className={titleClass}>{children}</h1>;
}

Title.defaultProps = {
    className: '',
    globalClassNames: [],
};

export default Title;
