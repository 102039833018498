import { createSelector } from 'reselect';

// types
import { RootState } from '../rootReducer';

// helpers
import { isEmailConsentBlock, isCountryUS } from 'helpers/utilsUpdated';

const selectUser = (state: RootState) => state.user;

const selectEmail = createSelector(selectUser, (user) => user.email);
const selectOrderId = createSelector(selectUser, (user) => user.orderId);
const selectUserId = createSelector(selectUser, (user) => user.user_id);
const selectEmailStatusMessage = createSelector(selectUser, (user) => user.emailStatus.message);
const selectEmailStatusIsValid = createSelector(selectUser, (user) => user.emailStatus.isValid);
const selectIsLoading = createSelector(selectUser, (user) => user.isLoading);
const selectToken = createSelector(selectUser, (user) => user.token);
const selectPaymentType = createSelector(selectUser, (user) => user.payment_type);
const selectGuideId = createSelector(selectUser, (user) => user.guideId);
const selectUserLoader = createSelector(selectUser, (user) => user.loader);
const selectCountry = createSelector(selectUser, (user) => user.country);
const selectDeepLinks = createSelector(selectUser, (user) => user.deepLink);
const selectIsDisplaySendpulseMailing = createSelector(
    selectUser,
    ({ isSendPulseActive, isSendPulseActiveDisplay, country }) => {
        return isSendPulseActiveDisplay && !isSendPulseActive && isEmailConsentBlock() && !isCountryUS(country);
    }
);

export {
    selectUser,
    selectEmail,
    selectOrderId,
    selectUserId,
    selectEmailStatusMessage,
    selectEmailStatusIsValid,
    selectIsLoading,
    selectToken,
    selectPaymentType,
    selectUserLoader,
    selectCountry,
    selectGuideId,
    selectDeepLinks,
    selectIsDisplaySendpulseMailing,
};
