import { COOKIE_ANALYTIC_LIST, ICookiePixel } from 'constants/analytics';

export const getAnalyticInitList = (cookieIdList: string[]) => {
    const analyticInitList: ICookiePixel[] = [];

    cookieIdList.forEach((cookieId) => {
        const cookieAnalytics = COOKIE_ANALYTIC_LIST[cookieId];

        cookieAnalytics && analyticInitList.push(...cookieAnalytics);
    });

    return analyticInitList;
};

export const getAllAnalyticInitList = () =>
    Object.values(COOKIE_ANALYTIC_LIST).reduce((acc, analytic) => [...acc, ...analytic], []);
