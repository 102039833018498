import { select, take, call, put } from 'redux-saga/effects';
import Solid from 'solid-payment';
import config from 'config';

// redux
import { selectToken } from 'redux/User/selectors';
import { selectSolidInited, selectPaymentConfigSandbox } from 'redux/Payment/selectors';
import { setSolidInited } from 'redux/Payment/actions';

// helpers
import { getLanguage } from 'helpers/utilsUpdated';

// types
import { RootState } from 'redux/rootReducer';

/*
 * Selector. The query depends by the state shape
 */
const getToken = (state: RootState) => selectToken(state);
const getSolidInit = (state: RootState) => selectSolidInited(state);
const getSandbox = (state: RootState) => selectPaymentConfigSandbox(state);

function* init() {
    try {
        Solid.setHeaders({
            apiUrl: config.API_URL,
            apiKey: config.API_KEY,
            apiVersion: 2,
            language: getLanguage(),
            sandbox: yield select(getSandbox),
            token: yield select(getToken),
        });
        console.info('%cinit - SOLID PAYMENT', 'color:green');

        yield put(setSolidInited(true));
    } catch (e) {
        /* eslint-disable no-console */
        console.log('Error', e);
    }
}

function* tokenWatcher() {
    let previous: string = yield select(getToken);

    while (true) {
        const solidStatus: boolean = yield select(getSolidInit);
        if (solidStatus) return;

        yield take('*'); // listening all actions
        const next: string = yield select(getToken);
        if ((!solidStatus && next) || next !== previous) {
            previous = next;
            yield call(init);
        }
    }
}

export const initSolidPayment = [tokenWatcher()];
