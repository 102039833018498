import { lazy } from 'react';

const lazyWithRetry = (componentImport) =>
    lazy(async () => {
        const isAlreadyReload = JSON.parse(localStorage.getItem('page-is-already-reload') || 'false');

        try {
            const component = await componentImport();

            localStorage.setItem('page-is-already-reload', 'false');

            return component;
        } catch (error) {
            if (!isAlreadyReload) {
                localStorage.setItem('page-is-already-reload', 'true');

                return location.reload();
            }

            throw error;
        }
    });

export default lazyWithRetry;
