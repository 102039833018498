import {
    FAST_EASY_GIRL,
    MENS_FASTING,
    FAST_EASY_WOMEN,
    FASTING_BOYS,
    MUSCULAR_FAST,
    PLUS_FASTING,
    PLUS_WOMEN_FAST,
    WOMEN_START_FASTING,
    PLUS_MEN_FAST,
    FAST_SLIM_GIRL,
} from 'constants/mirrorsHosts';

import fasteasygirlLogo from './img/fasteasygirlLogo.webp';
import mensfastingLogo from './img/mensfastingLogo.webp';
import fasteasywomenLogo from './img/fasteasywomenLogo.webp';
import fastingboysLogo from './img/fastingboysLogo.webp';
import muscularfastLogo from '/public/logos/muscularfastLogo.webp';
import plusfastingLogo from '/public/logos/plusfastingLogo.webp';
import pluswomenfastLogo from '/public/logos/pluswomenfastLogo.webp';
import womenstartfastingLogo from '/public/logos/womenstartfastingLogo.webp';
import plusmenfastLogo from '/public/logos/plusmenfastLogo.webp';
import fastslimgirlLogo from '/public/logos/fastslimgirlLogo.webp';

export const LOGO_CONFIG = {
    [FAST_EASY_GIRL]: {
        logoText: 'FastEasyGirl',
        logoImg: fasteasygirlLogo,
    },
    [MENS_FASTING]: {
        logoText: 'MensFasting',
        logoImg: mensfastingLogo,
    },
    [FAST_EASY_WOMEN]: {
        logoText: 'FastEasyWomen',
        logoImg: fasteasywomenLogo,
    },
    [FASTING_BOYS]: {
        logoText: 'FastingBoys',
        logoImg: fastingboysLogo,
    },
    [MUSCULAR_FAST]: {
        logoText: 'MuscularFast',
        logoImg: muscularfastLogo,
    },
    [PLUS_FASTING]: {
        logoText: 'PlusFasting',
        logoImg: plusfastingLogo,
    },
    [PLUS_WOMEN_FAST]: {
        logoText: 'PlusWomenFast',
        logoImg: pluswomenfastLogo,
    },
    [WOMEN_START_FASTING]: {
        logoText: 'Women Start Fasting',
        logoImg: womenstartfastingLogo,
    },
    [PLUS_MEN_FAST]: {
        logoText: 'PlusMenFast',
        logoImg: plusmenfastLogo,
    },
    [FAST_SLIM_GIRL]: {
        logoText: 'Fast Slim Girl',
        logoImg: fastslimgirlLogo,
    },
};
