import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

// redux
import { selectHideHeader } from 'redux/UiEffects/selectors';

function withHeaderConfig(WrappedComponent) {
    return class extends React.Component {
        static displayName = `withHeaderConfig(${WrappedComponent.displayName})`;

        constructor(props) {
            super(props);
            this.state = {
                hide: props.hide,
            };
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            if (this.state.hide_header !== nextProps.hide) {
                this.setState({
                    hide: nextProps.hide,
                });

                return true;
            }

            return false;
        }

        render() {
            return (
                <If condition={!this.state.hide}>
                    <WrappedComponent isFastEasy {...this.props}>
                        {this.props.children}
                    </WrappedComponent>
                </If>
            );
        }
    };
}

const mapStateToProps = (state) => {
    return {
        hide: selectHideHeader(state),
    };
};

const composedConfig = compose(connect(mapStateToProps, null), withHeaderConfig);
export default composedConfig;
