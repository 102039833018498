/* ToDo: FC_exp_overline experiment 24.09 */
/* ToDo: FC_exp_caption_small experiment 24.09 */

import React from 'react';
import classNames from 'classnames/bind';

// styles
import styles from './Text.module.css';
import base from 'assets/styles/Common.module.css';

const globalStyles = { ...styles, ...base };
const cx = classNames.bind(globalStyles);

function Text(props) {
    const {
        globalClassNames,
        children,
        medium,
        extraSmall,
        mediumSmall,
        mediumSmall2,
        small,
        bold,
        normal,
        caption,
        transparence,
        grey,
        darkGrey,
        centered,
        colorFast,
        withGreyBg,
        small2,
        white,
        uppercase,
        letterSpacing1,
        safeLight,
        red,
        FC_exp_overline,
        FC_exp_caption_small,
        underlineChild,
        downloadPageColor,
    } = props;

    const classes = globalClassNames.map((item) => ({ [item]: globalStyles[item] }));

    const textClass = cx(
        'default',
        {
            medium,
            extraSmall,
            mediumSmall,
            mediumSmall2,
            small,
            caption,
            grey,
            darkGrey,
            bold,
            normal,
            transparence,
            centered,
            colorFast,
            withGreyBg,
            small2,
            white,
            uppercase,
            letterSpacing1,
            safeLight,
            red,
            FC_exp_overline,
            FC_exp_caption_small,
            underlineChild,
            downloadPageColor,
        },
        classes
    );

    return <p className={textClass}>{children}</p>;
}

Text.defaultProps = {
    className: '',
    globalClassNames: [],
};

export default Text;
