/* eslint-disable no-console */
import { takeLatest, put, select, call } from 'redux-saga/effects';
import Solid from 'solid-payment';
import { Main } from 'wikr-core-analytics';

// redux
import * as actionTypes from 'redux/Payment/actionTypes';
import { SET_IS_PAID } from 'redux/User/actionTypes';
import { selectUserId } from 'redux/User/selectors';
import { selectTestaniaName, selectFlowLink } from 'redux/Testania/selectors';
import { selectCurrentProduct, selectPaymentMethod } from 'redux/Payment/selectors';
import {
    setPaymentType,
    setSubscriptionId,
    setValidatePaymentData,
    setValidatePaymentFail,
    validatePayment as validatePaymentAction,
} from 'redux/Payment/actions';

// helpers
import {
    cleanObject,
    deleteSlash,
    fromPennyToInt,
    getDeviceOS,
    getPriceFromCents,
    generateQueryParams,
} from 'helpers/utilsUpdated';

// interfaces
import { CurrentProduct, PaymentMethod } from 'types/payments/payments';
import { ValidateResponse, ValidatePayload } from 'types/payments/validate';
import { RootState } from 'redux/rootReducer';

// constants
import { DEVICE_OS, APP_FULL_ACCESS, PAYMENT_TYPES_NAME } from 'constants/payments';

const getUserId = (state: RootState) => selectUserId(state);
const getCurrentProduct = (state: RootState) => selectCurrentProduct(state);
const getPaymentMethod = (state: RootState) => selectPaymentMethod(state);
const testaniaName = (state: RootState) => selectTestaniaName(state);
const getFlowLink = (state: RootState) => selectFlowLink(state);

function* validate({ payload }: ReturnType<typeof validatePaymentAction>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    const order = payload.data?.order;

    const currentProduct: CurrentProduct = yield select(getCurrentProduct);
    const flow_link: string = yield select(getFlowLink);

    yield put(setPaymentType(currentProduct?.payment_type));

    const deviceOS: string = getDeviceOS();
    const isTargetDevice = deviceOS === DEVICE_OS.ANDROID || deviceOS === DEVICE_OS.IOS;
    const platform = isTargetDevice ? deviceOS : DEVICE_OS.ANDROID;
    const isTrial = !!currentProduct?.trial;

    const pageUrl = deleteSlash(payload.screenId);
    const ab_test_name: string = yield select(testaniaName);

    const meta = {
        payment_method: payload.paymentMethod,
        payment_id: order?.subscription_id ?? order?.order_id,
        payment_type: currentProduct.payment_type,
        product_id: currentProduct?.id,
        product_code: APP_FULL_ACCESS,
        platform,
        trial: isTrial,
        flow_link,
        ...(ab_test_name && { ab_test_name }),
        ...(pageUrl && { payment_screen: pageUrl }),
        ...(currentProduct?.period && {
            subscription_period: currentProduct?.period,
            subscription_trial_period: currentProduct?.trial,
        }),
    };

    try {
        const response: ValidateResponse = yield Solid.validate(payload?.system, meta);

        payload.data.amountWithoutCommission = Number(getPriceFromCents(currentProduct?.ltv));
        payload.data.isIntroductory = isTrial;
        payload.data.introductorySubscriptionPrice = currentProduct?.start_price;

        if (!response.result) throw response;

        yield call(sendAnalyticPurchase, payload);

        yield put({ type: SET_IS_PAID, payload: true });
        if (order?.subscription_id) yield put(setSubscriptionId(order.subscription_id));
        yield put(setValidatePaymentData({ ...response, result: true }));
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    } catch ({ error }) {
        console.error('error', error);
        alert('Something went wrong with payment validate: ' + error);

        yield put(setValidatePaymentFail(error));
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
    }
}

function* sendAnalyticPurchase(payload: ValidatePayload) {
    const {
        amountWithoutCommission,
        isIntroductory,
        introductorySubscriptionPrice,
        order: { amount, currency, subscription_id },
        brand,
    } = payload.data;

    const pageUrl = deleteSlash(payload.screenId);
    const product: CurrentProduct = yield select(getCurrentProduct);
    const paymentMethod: PaymentMethod = yield select(getPaymentMethod);
    const userId: number = yield select(getUserId);
    const ab_test_name: string = yield select(testaniaName);
    const urlParams = generateQueryParams();

    const content_id = isIntroductory
        ? `Product - Introductory Price ${fromPennyToInt(amount)} - Subscription price ${fromPennyToInt(
              introductorySubscriptionPrice
          )}`
        : `Product - Price ${fromPennyToInt(amount)}`;

    const options = {
        currency: currency,
        value: amountWithoutCommission,
        content_id,
        subscription_price: fromPennyToInt(introductorySubscriptionPrice),
        price: fromPennyToInt(amount),
        payment: PAYMENT_TYPES_NAME[paymentMethod],
        card_type: brand,
        urlParams,
        user_id: userId,
        order_id: payload?.data?.order?.order_id,
        subscription_id: subscription_id,
        screen_id: pageUrl,
        tariff: product.id,
        ab_test_name,
        order_type: product?.payment_type,
    };

    Main.purchase(cleanObject(options));
}

export const validatePayment = [takeLatest(actionTypes.VALIDATE_PAYMENT, validate)];
