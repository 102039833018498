// helpers
import { getToFixedNumber, isMaleGender } from 'helpers/utilsUpdated';

// constants
import { CONVERT_CONSTANTS, UNITS } from './constants';
import { IMPERIAL, METRIC } from 'constants/measureUnits';

// interfaces
import { MeasuringSystemType, IConvertMeasureSystemValues, ILabelByBmi } from './types';
import { IGender } from 'types/commonInterfaces';

class Convert {
    private readonly numberSystem: MeasuringSystemType;
    private readonly needConvertResult: boolean;

    constructor(numberSystem?: MeasuringSystemType) {
        this.numberSystem = numberSystem || METRIC;
        this.needConvertResult = numberSystem === IMPERIAL;
    }

    fromLbsToKg(lbs: number): number {
        return lbs / 2.2046;
    }

    fromKgToLbs(kg: number): number {
        return Math.round(kg * 2.2046);
    }

    fromInToCm(inch: number): number {
        return inch / 0.3937;
    }

    fromCmToIn(cm: number): number {
        return cm * 0.3937;
    }

    fromLittersToGallons(litters: number): number {
        return litters / 3.785;
    }

    getUnitByNumberSystem(type: IConvertMeasureSystemValues) {
        return UNITS[this.numberSystem][type];
    }

    getBMI(weight: number, height: number): number {
        return getToFixedNumber(weight / Math.pow(height / 100, 2));
    }

    getLoseWeightByBMI(bmi: number): number {
        const checkBMI1 = bmi < 34.99 ? 2 : 2.5;
        const checkBMI2 = bmi < 29.99 ? 1.5 : checkBMI1;

        const result = bmi < 24.99 ? 1 : checkBMI2;

        return this.needConvertResult ? this.fromKgToLbs(result) : result;
    }

    getLabelByBMI(bmi: number): ILabelByBmi {
        const getLabelByBMI1 = bmi < 29.99 ? 'Overweight' : 'Obesity';
        const getLabelByBMI2 = bmi < 24.99 ? 'Normal' : getLabelByBMI1;

        return bmi < 18.49 ? 'Underweight' : getLabelByBMI2;
    }

    dailyTargetBMR(
        gender: IGender,
        currentWeight = CONVERT_CONSTANTS.defaultCurrentWeight,
        height = CONVERT_CONSTANTS.defaultHeight,
        age = CONVERT_CONSTANTS.defaultAge
    ): number {
        const coef = isMaleGender(gender) ? +5 : -161;

        return 10 * +currentWeight + 6.25 * +height - 5 * +age + coef;
    }

    dailyTDE(bmr: number, activity: string = CONVERT_CONSTANTS.defaultFitnessLevel): number {
        return bmr * CONVERT_CONSTANTS.fitnessLvlToActivityLvl[activity || CONVERT_CONSTANTS.defaultFitnessLevel];
    }

    calorieRangeMin(tde: number): number {
        return 0.95 * tde;
    }

    getCurrentWeight(weight: number | string) {
        const fixedWeight = Math.round(Number(weight));

        return this.needConvertResult ? this.fromKgToLbs(fixedWeight) : fixedWeight;
    }

    roundNumber(val: number, bitness = 10): number {
        return Math.round(val / bitness) * bitness;
    }

    getCalorieRangeMax(tde: number): number {
        return 1.05 * tde;
    }

    getWaterPerDay(
        currentWeight = CONVERT_CONSTANTS.defaultCurrentWeight,
        activityLevel = CONVERT_CONSTANTS.defaultFitnessLevel
    ) {
        // 41.6745 = 2.205*2/3*28.35;
        const levelLitters =
            CONVERT_CONSTANTS.fitnessLvlToActivityLvl[activityLevel || CONVERT_CONSTANTS.defaultFitnessLevel];
        const coefByActivityLevel = CONVERT_CONSTANTS.activityLevelLitters[levelLitters];
        const litters = (currentWeight * 41.6745) / 1000 + coefByActivityLevel;

        return getToFixedNumber(
            this.needConvertResult ? this.fromLittersToGallons(litters) : getToFixedNumber(litters)
        );
    }

    fromFeetAndInchesToCentimeters(ft: string | number, inch: string | number) {
        const feetInCM = Number(ft) * 30.48;
        const inchesInCM = Number(inch) * 2.54;

        return getToFixedNumber(feetInCM + inchesInCM);
    }
}

export default Convert;
