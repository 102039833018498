import { createSelector } from 'reselect';

// types
import { RootState } from '../rootReducer';
import { PaymentStore } from 'interfaces/Store/paymentStore';

const selectPayment = (state: RootState): PaymentStore => state.payment;

const selectIsLoading = createSelector(selectPayment, (payment: PaymentStore) => payment.isLoading);
const selectPaymentValidate = createSelector(selectPayment, (payment) => payment.paymentValidate);
const selectPaymentMethod = createSelector(selectPayment, (payment) => payment.paymentMethod);
const selectPaymentConfigPaypal = createSelector(selectPayment, (payment) => payment.paymentConfig.paypal);
const selectPaymentConfigApplePay = createSelector(selectPayment, (payment) => payment.paymentConfig.applaPay);
const selectPaymentConfigSandbox = createSelector(selectPayment, (payment) => payment.paymentConfig.sandbox);
const selectSolidInited = createSelector(selectPayment, (payment) => payment.solidInited);
const selectBankCardSrc = createSelector(selectPayment, (payment) => payment.bankCardSrc);
const selectPayPalLink = createSelector(selectPayment, (payment) => payment.payPalLink);
const selectApplePay = createSelector(selectPayment, (payment) => payment.applePay);
const selectInitPaymentData = createSelector(selectPayment, (payment) => payment.initPaymentData);
const selectCheckoutOrderId = createSelector(selectPayment, (payment) => payment.checkoutOrderId);
const selectCurrentProduct = createSelector(selectPayment, (payment) => payment.currentProduct);
const selectCurrency = createSelector(selectPayment, (payment) => payment.currency);
const selectExclusiveOfferStatus = createSelector(selectPayment, (payment) => payment.exclusiveOfferStatus);
const selectPaymentType = createSelector(selectPayment, (payment) => payment.paymentType);
const selectSelectedTrialPrice = createSelector(selectPayment, (payment) => payment.selectedTrialPrice);
const selectSubscriptionId = createSelector(selectPayment, (payment) => payment.subscriptionId);
const selectUpsellId = createSelector(selectPayment, (payment) => payment.upsellId);
const selectPaymentDiscount = createSelector(selectPayment, (payment) => payment.paymentDiscount);
const selectValidatePaymentData = createSelector(selectPayment, (payment) => payment.validatePaymentData);
const selectOneClickPaymentPrice = createSelector(selectPayment, (payment) => payment.oneClickPaymentPrice);
const selectIsPaymentScreen = createSelector(selectPayment, (payment) => payment.isPaymentScreen);
const selectIsCustomPaymentButton = createSelector(selectPayment, (payment) => payment.isCustomPaymentButton);
const selectIsHideCustomPaymentButton = createSelector(selectPayment, (payment) => payment.isHideCustomPaymentButton);
const selectIsDisplayCustomPaymentButton = createSelector(
    selectPayment,
    ({ isCustomPaymentButton, isHideCustomPaymentButton }) => isCustomPaymentButton || isHideCustomPaymentButton
);
const selectMerchantData = createSelector(selectPayment, (payment) => payment.merchantData);
const selectAllProductsFromFirstPayment = createSelector(
    selectPayment,
    (payment) => payment.allProductsFromFirstPayment
);
const selectIsApplePayAvailable = createSelector(selectPayment, (payment) => payment.isApplePayAvailable);
const selectIsGooglePayAvailable = createSelector(selectPayment, (payment) => payment.isGooglePayAvailable);

export {
    selectPayment,
    selectApplePay,
    selectBankCardSrc,
    selectCheckoutOrderId,
    selectCurrency,
    selectCurrentProduct,
    selectExclusiveOfferStatus,
    selectInitPaymentData,
    selectIsLoading,
    selectPayPalLink,
    selectPaymentConfigPaypal,
    selectPaymentConfigApplePay,
    selectPaymentConfigSandbox,
    selectPaymentMethod,
    selectPaymentType,
    selectPaymentValidate,
    selectSelectedTrialPrice,
    selectSolidInited,
    selectSubscriptionId,
    selectUpsellId,
    selectPaymentDiscount,
    selectValidatePaymentData,
    selectOneClickPaymentPrice,
    selectIsPaymentScreen,
    selectIsCustomPaymentButton,
    selectIsHideCustomPaymentButton,
    selectIsDisplayCustomPaymentButton,
    selectMerchantData,
    selectAllProductsFromFirstPayment,
    selectIsApplePayAvailable,
    selectIsGooglePayAvailable,
};
