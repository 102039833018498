/* eslint-disable max-lines */
// TODO: when all helpers from utils.js migrate to ts need rename this file and remove utils.js
import { AnalyticUtils } from 'wikr-core-analytics';
import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { uaParser } from 'wikr-core-analytics';
import dayjs, { Dayjs } from 'dayjs';

import { getUrlParams } from 'helpers/utils';

import config from 'config';
import {
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_MONTH,
    DAYS_PER_ONE_YEAR,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_WEEK,
    DAYS_PER_YEAR,
} from 'constants/payments';
import { COUNTRIES_LIST, DEFAULT_COUNTRY } from 'constants/countriesList';
import { DEFAULT_LANGUAGE } from 'constants/localization';
import { WEIGHT_UNIT } from 'constants/measureUnits';
import { IMPERIAL, METRIC } from 'constants/measureUnits';
import { MALE } from 'constants/gender';
import { MONTH_FORMAT } from 'constants/dayjsFormats';
import {
    FAST_EASY_GIRL,
    FAST_EASY_WOMEN,
    FASTING_BOYS,
    MENS_FASTING,
    MUSCULAR_FAST,
    PLUS_FASTING,
    PLUS_WOMEN_FAST,
    WOMEN_START_FASTING,
    PLUS_MEN_FAST,
    FAST_SLIM_GIRL,
} from 'constants/mirrorsHosts';

import Convert from './Convert/ConvertNew';

import { IGender, IGetTargetDateArgs } from 'types/commonInterfaces';
import { CurrentProduct } from 'interfaces/Payments/payments';

const convertUnits = new Convert();

const UAParser = new uaParser();

const KEYS_TO_REMOVE = [
    'currentBranchName',
    'token',
    'loginStatus',
    'paidStatus',
    'config',
    'completedOBs',
    'paymentType',
    'page-is-already-reload',
    'persist:root',
    'release_date',
    'uuid',
    'testania_name',
    'pusherTransportTLS',
    'testaniaResponseStatus',
];

const WIDTH_SIZE = 2000;
const HEIGHT_SIZE = 2000;

const I_OS = 'iOS';
const FACEBOOK_BROWSER = 'Facebook';

export const checkFbBots = (): boolean => {
    const windowWidth = window.screen.width;
    const windowHeight = window.screen.height;

    return windowWidth === WIDTH_SIZE && windowHeight === HEIGHT_SIZE;
};

export const getNumberSystem = (unit?: string): string => {
    const mapItems: Record<string, string> = {
        lbs: IMPERIAL,
        in: IMPERIAL,
        cm: METRIC,
        kg: METRIC,
    };

    return unit ? mapItems[unit] : IMPERIAL;
};

const getTranslatedDate = (value: number, type: string, paymentType: string): string => {
    const { t } = useTranslation();

    const pluralStatement =
        value >= 2 && value <= 4
            ? t(`pluralSecondForm${type}${paymentType}`, { count: value })
            : t(`plural${type}${paymentType}`, { count: value });

    return value === 1 ? t(`single${type}${paymentType}`, { count: value }) : pluralStatement;
};

export const normalizeDate = (countOfWeek: string | number, paymentType = ''): string => {
    const oneYearInWeeks = 12 * 4;
    const numberCountOfWeek = +countOfWeek;

    if (numberCountOfWeek < 4) {
        return getTranslatedDate(numberCountOfWeek, 'Week', paymentType);
    } else if (numberCountOfWeek < oneYearInWeeks) {
        return getTranslatedDate(Math.round(numberCountOfWeek / 4), 'Month', paymentType);
    } else {
        return getTranslatedDate(Math.round(numberCountOfWeek / oneYearInWeeks), 'Year', paymentType);
    }
};

export const deleteSlash = (word: string): string => word.replace(/\//g, '');

export const replaceUnderscore = (string: string): string => string.replace(/_/g, '-');

export const cleanObject = <T>(object: Record<string, unknown>): Record<string, unknown> | T => {
    for (const propName in object) {
        if (object[propName] === null || object[propName] === undefined) {
            delete object[propName];
        }
    }

    return object;
};

export const isEmpty = (value: null | undefined | object | string): boolean => {
    return (
        value === null ||
        value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const getRelease = (): string => {
    return JSON.parse(config.RELEASE);
};

export const getProjectEmail = (): string | undefined => {
    switch (window.location.host) {
        case FAST_EASY_GIRL:
            return 'support@fasteasygirl.com';
        case FAST_EASY_WOMEN:
            return 'support@fasteasywomen.com';
        case MENS_FASTING:
            return 'support@mensfasting.com';
        case FASTING_BOYS:
            return 'support@fastingboys.com';
        case MUSCULAR_FAST:
            return 'support@muscularfast.com';
        case PLUS_FASTING:
            return 'support@plusfasting.com';
        case PLUS_WOMEN_FAST:
            return 'support@pluswomenfast.com';
        case WOMEN_START_FASTING:
            return 'support@womenstartfasting.com';
        case PLUS_MEN_FAST:
            return 'support@plusmenfast.com';
        case FAST_SLIM_GIRL:
            return 'support@fastslimgirl.com';
    }
};

export const joinClasses = (...arg: string[]): string => {
    return [...arg].join(' ');
};

export const fromPennyToInt = (penny: number): number => penny / 100;

export const isRemarketingFlow = () => {
    const urlParams = getUrlParams();

    const token = urlParams?.token;

    return Boolean(token);
};

export const isEmailConsentBlock = () =>
    isRemarketingFlow() || Boolean(JSON.parse(localStorage.getItem('urlParams') || '{}')?.token);

export const getDeviceOS = (): string => {
    const userAgent = window.navigator.userAgent;
    const getOSData = UAParser.setUA(userAgent).getOS();

    return getOSData?.name?.toUpperCase() || 'DEFAULT_DEVICE';
};

export const checkIsFacebookBtnAvailable = () => {
    const userAgent = window.navigator.userAgent;
    const uaParser = UAParser.setUA(userAgent);

    const deviceOS = uaParser?.getOS();
    const deviceBrowser = uaParser?.getBrowser();

    const isFacebook = deviceBrowser?.name === FACEBOOK_BROWSER;
    const isIOs = deviceOS?.name === I_OS;

    return isFacebook && isIOs;
};

export const scrollToTop = (): void => {
    window.scrollTo(0, 0);
};

export const scrollToTopSmooth = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const updateGlobalLoaderVisibility = (isVisible: boolean): void => {
    const globalLoader = document.getElementById('app-loader');

    if (globalLoader) {
        globalLoader.style.display = isVisible ? 'block' : 'none';
    }
};

export const getFaviconElement = (id = 'favicon'): HTMLElement | null => {
    return document.getElementById(id);
};

export const getParamFromUrl = (paramName: string) => {
    const params = new URLSearchParams(window.location.search);

    return params.get(paramName);
};

export const periodNormalizer = (period: number): string => {
    if (period === DAYS_PER_YEAR) {
        return '1 Year';
    } else if (period === DAYS_PER_WEEK) {
        return '1 Week';
    } else if (period === DAYS_PER_MONTH) {
        return '1 Month';
    } else if (period === DAYS_PER_TWO_MONTH) {
        return '2 Months';
    } else if (period === DAYS_PER_THREE_MONTH) {
        return '3 Months';
    } else if (period === DAYS_PER_FOUR_MONTH) {
        return '4 Months';
    } else if (period === DAYS_PER_FIVE_MONTH) {
        return '5 Months';
    } else if (period === DAYS_PER_SIX_MONTH) {
        return '6 Months';
    } else if (period === DAYS_PER_SEVEN_MONTH) {
        return '7 Months';
    } else if (period === DAYS_PER_EIGHT_MONTH) {
        return '8 Months';
    }

    return '0_null'; // fix broken build
};

export const trialNormalizer = (trial: number): string => {
    if (trial === DAYS_PER_WEEK) {
        return '7-day Trial';
    }

    return '0_null'; // fix broken build
};

export const dateNormalizer = (days: number) => {
    if (days === DAYS_PER_YEAR) {
        return '1_year plan';
    } else if (days === DAYS_PER_MONTH) {
        return '1_month plan';
    } else if (days === DAYS_PER_TWO_MONTH) {
        return '2_month plan';
    } else if (days === DAYS_PER_THREE_MONTH) {
        return '3_month plan';
    } else if (days === DAYS_PER_FOUR_MONTH) {
        return '4_month plan';
    } else if (days === DAYS_PER_FIVE_MONTH) {
        return '5_month plan';
    } else if (days === DAYS_PER_SIX_MONTH) {
        return '6_month plan';
    } else if (days === DAYS_PER_SEVEN_MONTH) {
        return '7_month plan';
    } else if (days === DAYS_PER_EIGHT_MONTH) {
        return '8_month plan';
    } else if (days === DAYS_PER_WEEK) {
        return '1_week plan';
    }

    return '0_null'; // fix broken build
};

export const stateNormalizer = (productState: string): string => {
    switch (productState) {
        case 'per_day':
            return 'day';
        case 'per_week':
            return 'week';
        case 'per_month':
        default:
            return 'month';
    }
};

export const parsePaymentType = (period: number): string => dateNormalizer(period).replace('_', '-');

export const getDiscount = (oldPrice: number, newPrice: number): string => {
    return (((oldPrice - newPrice) / oldPrice) * 100).toFixed();
};

export const getMinElemFromArr = (arr: Record<string, number>[], fieldName: string) => {
    return arr.reduce((prev, cur) => (prev[fieldName] < cur[fieldName] ? prev : cur));
};

export const getMaxElemFromArr = (arr: Array<{ [fieldName: string]: number }>, fieldName: string) => {
    return arr.reduce((prev, cur) => (prev[fieldName] > cur[fieldName] ? prev : cur));
};

export const getToFixedNumber = (number: string | number, numbersAfterComma = 2): number => {
    const numberValue = Number(number);

    return Number(numberValue.toFixed(numbersAfterComma));
};

export const getPriceFromCents = (price: string | number): number => {
    const priceValue = Number(price);

    return getToFixedNumber(priceValue / 100);
};

export const isIntroProduct = (startPrice: number, newPrice: number): boolean => startPrice > newPrice;

export const getBestValueProduct = (products: Record<string, number>[]): number => {
    return getMinElemFromArr(products, 'price_per_state').price_per_state;
};
export const isTrialProduct = (period: number): boolean => period === DAYS_PER_WEEK || period === DAYS_PER_MONTH;

export const isTrialProductWithCorrectPeriodType = (period: number, periodType: string): boolean => {
    if (isTrialProduct(period)) {
        switch (periodType) {
            case 'per month':
            case 'per week':
                return true;
            default:
                return false;
        }
    }

    return false;
};

export const isBestValueProductWithTrial = (period: number): boolean =>
    period === DAYS_PER_SIX_MONTH || period === DAYS_PER_ONE_YEAR;

export const getNewConfigurableCardsCaption = (productIndex: number): string => {
    switch (productIndex) {
        case 0:
            return 'paymentFlow.paymentE1.productCards.caption1';
        case 1:
            return 'paymentFlow.paymentE1.productCards.caption2';
        case 2:
            return 'paymentFlow.paymentE1.productCards.caption3';
        default:
            return '';
    }
};

export const getTrialConfigurableCardsCaptionByPeriod = (period: number): string => {
    switch (period) {
        case DAYS_PER_WEEK:
        case DAYS_PER_MONTH:
            return 'paymentFlow.paymentE1.productCards.caption1';
        case DAYS_PER_SIX_MONTH:
        case DAYS_PER_ONE_YEAR:
            return 'paymentFlow.paymentE1.productCards.caption3';
        case DAYS_PER_TWO_MONTH:
        case DAYS_PER_THREE_MONTH:
        case DAYS_PER_FOUR_MONTH:
        case DAYS_PER_FIVE_MONTH:
            return 'paymentFlow.paymentE1.productCards.caption2';
        default:
            return '';
    }
};

export const isCurrentProductHasPairFunc = (products: CurrentProduct[], period: number): boolean =>
    products.some((product) => product.period === period);

export const getBirthday = (age: string | number): string => {
    const today = new Date();

    return `${today.getFullYear() - Number(age)}-01-01`;
};

export const startCalculateProgress = ({
    setProgress,
    onComplete,
}: {
    setProgress: (progress: number) => void;
    onComplete?: () => void;
}): void => {
    const path = window.location.href;

    let currentProgress = 0;
    let speed = 50;

    setTimeout(frame, speed);

    async function frame() {
        if (currentProgress === 100) {
            currentProgress = 0;
            onComplete && onComplete();
        } else if (path === window.location.href) {
            if (currentProgress >= 38 && currentProgress < 45) {
                speed = 250;
            } else if (currentProgress >= 45 && currentProgress < 63) {
                speed = 50;
            } else if (currentProgress >= 63 && currentProgress < 75) {
                speed = 60;
            } else if (currentProgress >= 75 && currentProgress < 82) {
                speed = 250;
            } else if (currentProgress >= 82 && currentProgress < 95) {
                speed = 70;
            } else if (currentProgress >= 96) {
                speed = 500;
            }

            setProgress(Math.round(++currentProgress));
            setTimeout(frame, speed);
        }
    }
};
// here we check if some arg is string, it can be any arg so stay here any
export const isString = (value: any): boolean => typeof value.valueOf() === 'string';

export const replaceToUnderscore = (string: string): string => string.replace(/-/g, '_');

export const getOnlyPositiveNumber = (digit: number): number => (digit <= 0 ? 1 : digit);

export const enableOnlyDigitsInput = (event: KeyboardEvent): void => {
    if (event.key === 'Tab' || event.key === 'Backspace') {
        return;
    }
    if (event.key < '0' || event.key > '9') {
        event.preventDefault();
    }
};

export const generateQueryParams = (): string => {
    const paramsLocalSt = localStorage.getItem('urlParams');
    const defaultUrlParams = AnalyticUtils.getDefaultUrlParams();

    const params = paramsLocalSt ? JSON.parse(paramsLocalSt) : defaultUrlParams;
    const searchParams = new URLSearchParams();

    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

    return searchParams.toString();
};

export const deleteConfig = (): void => {
    KEYS_TO_REMOVE.forEach((key) => localStorage.removeItem(key));

    localStorage.setItem('isResetStore', 'true');
};

export const getLanguage = (): string => localStorage.getItem('language') || DEFAULT_LANGUAGE;

export const getIsCountryCodeValid = (country: string): boolean => COUNTRIES_LIST.some(({ code }) => code === country);

export const validateCountry = (country: unknown): boolean => {
    const countryCodeList = COUNTRIES_LIST.map((el) => el.code);

    return typeof country === 'string' && countryCodeList.includes(country);
};

export const isCountryUS = (country: string) => country === DEFAULT_COUNTRY;

export const getRecommendedWeightValues = (height: number | string, measure: string): string => {
    const { t } = useTranslation();
    const heightConverted = Number(height);

    const TOP_WEIGHT_RECOMMENDATION_VALUE = 24;
    const BOTTOM_WEIGHT_RECOMMENDATION_VALUE = 19;

    const isMetric = measure === METRIC;
    const unit = t(`measureUnits.${WEIGHT_UNIT[measure]}`);

    const [weightMin, weightMax] = [BOTTOM_WEIGHT_RECOMMENDATION_VALUE, TOP_WEIGHT_RECOMMENDATION_VALUE].map(
        (mutator) => {
            const value = Math.round(mutator * (Math.pow(heightConverted, 2) / 10000));

            return isMetric ? value : convertUnits.fromKgToLbs(value);
        }
    );

    return `${weightMin} ${unit} - ${weightMax} ${unit}`;
};

export const getBMR = (gender: IGender, currentWeight: number, height: number, age: number): number => {
    const genderBasedValue = isMaleGender(gender) ? 5 : -161;

    return 10 * currentWeight + 6.25 * height - 5 * age + genderBasedValue;
};

export const getGlassesCount = (waterAmount: number): number => {
    const waterRecommendationInLiters = waterAmount / 1000;

    if (waterRecommendationInLiters >= 2 && waterRecommendationInLiters <= 3) {
        return 7;
    }

    if (waterRecommendationInLiters > 3) {
        return 10;
    }

    // default value
    return 4;
};

export const getWeeklyWeightLoss = (bmi: number): number => {
    let generalWeightLoss = 1.5;

    if (bmi === Infinity) {
        return generalWeightLoss;
    }

    if (bmi < 25) {
        generalWeightLoss = 1;
    }

    if (30 <= bmi && bmi < 35) {
        generalWeightLoss = 2;
    }

    if (35 <= bmi) {
        generalWeightLoss = 2.5;
    }

    return generalWeightLoss;
};

export const getPAL = (jobActive: string): number => {
    const JOB_ACTIVE_MAP: Record<string, number> = {
        lightly_active: 1.2,
        moderately_active: 1.55,
        very_active: 1.725,
    };

    return JOB_ACTIVE_MAP[jobActive];
};

export const getGeneralWeightLoss = (currentWeight: number, targetWeight: number): number =>
    currentWeight - targetWeight;

export const getGlassArray = (countGlasses: number): number[] => {
    const resultArray = new Array(10);

    return resultArray.fill(0).fill(1, 0, countGlasses);
};

export const getFastingLevel = (value: string): string => {
    if (value === 'A couple of things') {
        return 'intermediate';
    }

    if (value === 'I\'m experienced in fasting') {
        return 'advanced';
    }

    return 'beginner';
};

export const getWindowSize = (width: string | number = '1024'): boolean => {
    const mediaQuery = window.matchMedia(`(min-width: ${width}px)`);

    return mediaQuery.matches;
};

export const isMaleGender = (gender: IGender): boolean => {
    return gender === MALE;
};

export const getBMIBasedWeightLossIndexPerWeek = (bmi: number): number => {
    let returnedBmi = 2;

    if (bmi <= 24.9) {
        returnedBmi = 1;
    } else if (bmi >= 25 && bmi <= 29.9) {
        returnedBmi = 1.5;
    } else if (bmi >= 30 && bmi <= 34.9) {
        returnedBmi = 1.7;
    }

    return returnedBmi;
};

export const getTargetDate = ({ weightDiff, bmi, extraDate = 0 }: IGetTargetDateArgs): Dayjs => {
    const weeks = weightDiff / getBMIBasedWeightLossIndexPerWeek(bmi);

    const weeksCounted = weightDiff > 0 ? weeks : 0;

    if (extraDate) {
        return dayjs().add(Math.round(weeksCounted), 'week').add(extraDate, 'days');
    }

    return dayjs().add(Math.round(weeksCounted), 'week');
};

interface IGetMonthList {
    targetDate: Dayjs;
    monthBefore: number;
    monthAfter: number;
}

export const getMonthList = ({ targetDate, monthBefore, monthAfter }: IGetMonthList) => {
    const getMonth = (monthFromNow: number) => targetDate.add(monthFromNow, 'month').format(MONTH_FORMAT);

    const monthList = [];

    for (let i = monthBefore; i <= monthAfter; i++) {
        monthList.push(getMonth(i));
    }

    return monthList;
};

export const getAnimationDataLists = (prognoseDate: Dayjs, diffWithPrognose: number) => {
    const diffDayList: string[] = [];
    const diffMonthList: string[] = [];

    for (let i = diffWithPrognose; i >= 0; i--) {
        const prevPrognoseDate = prognoseDate.add(i, 'days');
        const prevPrognoseMonthFormatted = prevPrognoseDate.format('MMMM');

        if (!diffMonthList.includes(prevPrognoseMonthFormatted)) {
            diffMonthList.push(prevPrognoseMonthFormatted);
        }

        diffDayList.push(prognoseDate.add(i, 'days').format('DD'));
    }

    return {
        animationDayList: diffDayList,
        animationMonthList: diffMonthList,
    };
};
