import { getCheckFlow } from './helpers';
import { INTRODUCTORY_A_FLOW_PAYMENT_ID, INTRODUCTORY_B_FLOW_PAYMENT_ID } from 'constants/payments';

export const getCheckedFlows = (config) => {
    const { payment_flow } = config;

    const checkFlow = getCheckFlow(payment_flow);

    const isIntroductoryAPage = checkFlow(INTRODUCTORY_A_FLOW_PAYMENT_ID);
    const isIntroductoryBPage = checkFlow(INTRODUCTORY_B_FLOW_PAYMENT_ID);

    return {
        isIntroductoryAPage,
        isIntroductoryBPage,
    };
};
